// extracted by mini-css-extract-plugin
export var __wab_imgSpacer = "PlasmicProductCard-module--__wab_img-spacer--32y2l";
export var freeBox___2Fkh0 = "PlasmicProductCard-module--freeBox___2Fkh0--vkrWI";
export var freeBox___4FUfu = "PlasmicProductCard-module--freeBox___4FUfu--WIhZs";
export var freeBox__cuUee = "PlasmicProductCard-module--freeBox__cuUee--5TZg1";
export var freeBox__tcb40 = "PlasmicProductCard-module--freeBox__tcb40--x1PhZ";
export var img__hN9No = "PlasmicProductCard-module--img__hN9No--l5whv";
export var ratings__ppOXp = "PlasmicProductCard-module--ratings__ppOXp--IJmoM";
export var root = "PlasmicProductCard-module--root--eYhM1";
export var slotTargetPrice = "PlasmicProductCard-module--slotTargetPrice--l6ghQ";
export var slotTargetTitle = "PlasmicProductCard-module--slotTargetTitle--eGPbX";
export var svg___4OJ2V = "PlasmicProductCard-module--svg___4OJ2V--MPkXp";
export var svg__ljO4Z = "PlasmicProductCard-module--svg__ljO4Z--F5VKK";
export var svg__xjx32 = "PlasmicProductCard-module--svg__xjx32--xs6EP";
export var text__iaWwR = "PlasmicProductCard-module--text__iaWwR--zv7bg";
export var text__xtetv = "PlasmicProductCard-module--text__xtetv--Y0Gnp";