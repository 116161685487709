// extracted by mini-css-extract-plugin
export var freeBox = "PlasmicRatings-module--freeBox--4pjvR";
export var root = "PlasmicRatings-module--root--Lh0py";
export var slotTargetNumReviews = "PlasmicRatings-module--slotTargetNumReviews--wS-lF";
export var svg___7JQwa = "PlasmicRatings-module--svg___7JQwa--OJSby";
export var svg___95PmW = "PlasmicRatings-module--svg___95PmW--BP9EZ";
export var svg___9Q5Cd = "PlasmicRatings-module--svg___9Q5Cd--9ptay";
export var svg__bjKaC = "PlasmicRatings-module--svg__bjKaC--2WFGu";
export var svg__ew7Qa = "PlasmicRatings-module--svg__ew7Qa--zUrB6";
export var svg__h9Tv0 = "PlasmicRatings-module--svg__h9Tv0--+2sDQ";
export var svg__lqAJp = "PlasmicRatings-module--svg__lqAJp--x5rt5";
export var svg__nlsIn = "PlasmicRatings-module--svg__nlsIn--zcn44";
export var svg__skF4I = "PlasmicRatings-module--svg__skF4I--n-Nu7";
export var svg__uYfBu = "PlasmicRatings-module--svg__uYfBu--YnC56";
export var svgstars_five___95PmWdAe4Z = "PlasmicRatings-module--svgstars_five___95PmWdAe4Z--YcNKU";
export var svgstars_five__bjKaCdAe4Z = "PlasmicRatings-module--svgstars_five__bjKaCdAe4Z--J1CW0";
export var svgstars_five__h9Tv0DAe4Z = "PlasmicRatings-module--svgstars_five__h9Tv0DAe4Z--eguUO";
export var svgstars_five__lqAJpDAe4Z = "PlasmicRatings-module--svgstars_five__lqAJpDAe4Z--EAWwv";
export var svgstars_five__uYfBudAe4Z = "PlasmicRatings-module--svgstars_five__uYfBudAe4Z--heOco";
export var svgstars_four___95PmWwlptu = "PlasmicRatings-module--svgstars_four___95PmWwlptu--DD+tm";
export var svgstars_four___9Q5Cdwlptu = "PlasmicRatings-module--svgstars_four___9Q5Cdwlptu--Mkl++";
export var svgstars_four__bjKaCwlptu = "PlasmicRatings-module--svgstars_four__bjKaCwlptu--SzYGZ";
export var svgstars_four__lqAJpwlptu = "PlasmicRatings-module--svgstars_four__lqAJpwlptu--FkvFA";
export var svgstars_four__uYfBUwlptu = "PlasmicRatings-module--svgstars_four__uYfBUwlptu--VNNFW";
export var svgstars_one___7JQwauMy6R = "PlasmicRatings-module--svgstars_one___7JQwauMy6R--N3L-6";
export var svgstars_one___9Q5CduMy6R = "PlasmicRatings-module--svgstars_one___9Q5CduMy6R--hMCZj";
export var svgstars_one__bjKaCuMy6R = "PlasmicRatings-module--svgstars_one__bjKaCuMy6R--iyeCH";
export var svgstars_one__nlsInuMy6R = "PlasmicRatings-module--svgstars_one__nlsInuMy6R--xEJvB";
export var svgstars_one__skF4IuMy6R = "PlasmicRatings-module--svgstars_one__skF4IuMy6R--j+MzG";
export var svgstars_three___9Q5CdgmHjh = "PlasmicRatings-module--svgstars_three___9Q5CdgmHjh--wr5hQ";
export var svgstars_three__bjKaCgmHjh = "PlasmicRatings-module--svgstars_three__bjKaCgmHjh--qMa2K";
export var svgstars_three__lqAJpgmHjh = "PlasmicRatings-module--svgstars_three__lqAJpgmHjh--qZ-Wt";
export var svgstars_three__nlsIngmHjh = "PlasmicRatings-module--svgstars_three__nlsIngmHjh--6Ea-o";
export var svgstars_three__uYfBUgmHjh = "PlasmicRatings-module--svgstars_three__uYfBUgmHjh--FFUr1";
export var svgstars_two___7JQwaSmpEf = "PlasmicRatings-module--svgstars_two___7JQwaSmpEf--4FuKa";
export var svgstars_two___9Q5CdSmpEf = "PlasmicRatings-module--svgstars_two___9Q5CdSmpEf--oq5tg";
export var svgstars_two__bjKaCSmpEf = "PlasmicRatings-module--svgstars_two__bjKaCSmpEf--cfUy2";
export var svgstars_two__lqAJpSmpEf = "PlasmicRatings-module--svgstars_two__lqAJpSmpEf--QFZDx";
export var svgstars_two__nlsInSmpEf = "PlasmicRatings-module--svgstars_two__nlsInSmpEf--P6nYR";
export var svgstars_zero___7JQwa8966U = "PlasmicRatings-module--svgstars_zero___7JQwa8966U--azQmJ";
export var svgstars_zero___9Q5Cd8966U = "PlasmicRatings-module--svgstars_zero___9Q5Cd8966U--gdF0P";
export var svgstars_zero__ew7Qa8966U = "PlasmicRatings-module--svgstars_zero__ew7Qa8966U--tXeVj";
export var svgstars_zero__nlsIn8966U = "PlasmicRatings-module--svgstars_zero__nlsIn8966U--CzdcY";
export var svgstars_zero__skF4I8966U = "PlasmicRatings-module--svgstars_zero__skF4I8966U--VVZA+";