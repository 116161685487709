// extracted by mini-css-extract-plugin
export var __wab_imgSpacer = "PlasmicFooter-module--__wab_img-spacer--MA-BW";
export var freeBox___9GL9 = "PlasmicFooter-module--freeBox___9GL9--i6OCu";
export var freeBox__cnPpO = "PlasmicFooter-module--freeBox__cnPpO--WMRC6";
export var freeBox__e7Qio = "PlasmicFooter-module--freeBox__e7Qio--ZUyYI";
export var freeBox__iTln = "PlasmicFooter-module--freeBox__iTln--eut+S";
export var freeBox__jh2Vq = "PlasmicFooter-module--freeBox__jh2Vq--8Vexn";
export var freeBox__s3GHc = "PlasmicFooter-module--freeBox__s3GHc--kztRN";
export var freeBox__u5Xv1 = "PlasmicFooter-module--freeBox__u5Xv1--V0j45";
export var freeBox__xByUv = "PlasmicFooter-module--freeBox__xByUv--4aAw+";
export var h1 = "PlasmicFooter-module--h1--ARZoG";
export var h2 = "PlasmicFooter-module--h2--YN1KZ";
export var img = "PlasmicFooter-module--img--YJoVP";
export var link___9Djyc = "PlasmicFooter-module--link___9Djyc--DAT0P";
export var link__c1LsG = "PlasmicFooter-module--link__c1LsG--q1Mer";
export var link__jCtlS = "PlasmicFooter-module--link__jCtlS--swCMb";
export var link__o6NyD = "PlasmicFooter-module--link__o6NyD---nLo5";
export var link__rKL = "PlasmicFooter-module--link__rKL--jCAd8";
export var menuButton = "PlasmicFooter-module--menuButton--+4D5X";
export var root = "PlasmicFooter-module--root--yF-ut";
export var svg__cgOim = "PlasmicFooter-module--svg__cgOim--iCgzr";
export var svg__naZp = "PlasmicFooter-module--svg__naZp--9DRWe";
export var text__d5Wv8 = "PlasmicFooter-module--text__d5Wv8--TTIK5";
export var text__uXqGv = "PlasmicFooter-module--text__uXqGv--Dbw19";