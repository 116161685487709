// extracted by mini-css-extract-plugin
export var freeBox___2Hd5G = "PlasmicFeatureItem-module--freeBox___2Hd5G--wLVpt";
export var freeBox__gliop = "PlasmicFeatureItem-module--freeBox__gliop--EPLPS";
export var freeBox__msLDh = "PlasmicFeatureItem-module--freeBox__msLDh--gUELp";
export var freeBox__pr3U3 = "PlasmicFeatureItem-module--freeBox__pr3U3--dUGed";
export var freeBox__qvWkN = "PlasmicFeatureItem-module--freeBox__qvWkN--xteqJ";
export var freeBox__vobRw = "PlasmicFeatureItem-module--freeBox__vobRw--FgHkg";
export var linkButton = "PlasmicFeatureItem-module--linkButton--EFcOG";
export var root = "PlasmicFeatureItem-module--root--pECZn";
export var text___0JW74 = "PlasmicFeatureItem-module--text___0JW74--rIzsy";
export var text___1MXf = "PlasmicFeatureItem-module--text___1MXf--eusHS";
export var text___5FyFl = "PlasmicFeatureItem-module--text___5FyFl--kDw2L";
export var text__d8AFn = "PlasmicFeatureItem-module--text__d8AFn--QZDIy";