// extracted by mini-css-extract-plugin
export var __wab_imgSpacer = "PlasmicBlogPost-module--__wab_img-spacer--nd198";
export var freeBox__aAs86 = "PlasmicBlogPost-module--freeBox__aAs86--rOwZ+";
export var freeBox__i68Yi = "PlasmicBlogPost-module--freeBox__i68Yi--H2hQd";
export var freeBoxlong__aAs86AFZpZ = "PlasmicBlogPost-module--freeBoxlong__aAs86AFZpZ--KANz0";
export var freeBoxlong__i68YIaFZpZ = "PlasmicBlogPost-module--freeBoxlong__i68YIaFZpZ--y-aM5";
export var img___4TwBq = "PlasmicBlogPost-module--img___4TwBq--FkELf";
export var root = "PlasmicBlogPost-module--root--gtfT7";
export var rootlong = "PlasmicBlogPost-module--rootlong--PEzO3";
export var slotTargetAuthor = "PlasmicBlogPost-module--slotTargetAuthor--GvMUJ";
export var slotTargetDescription = "PlasmicBlogPost-module--slotTargetDescription--bIklN";
export var slotTargetDescriptionlong = "PlasmicBlogPost-module--slotTargetDescriptionlong--AgVOe";
export var slotTargetTitle = "PlasmicBlogPost-module--slotTargetTitle--z8fn1";