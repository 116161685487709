// extracted by mini-css-extract-plugin
export var root = "PlasmicLinkButton-module--root--NPunh";
export var rootsize_small = "PlasmicLinkButton-module--rootsize_small--PTP9U";
export var roottype_blankGray = "PlasmicLinkButton-module--roottype_blankGray--gqcEU";
export var roottype_blankOrange = "PlasmicLinkButton-module--roottype_blankOrange--rBclU";
export var roottype_outlineGray = "PlasmicLinkButton-module--roottype_outlineGray--AzNh6";
export var roottype_solidGray = "PlasmicLinkButton-module--roottype_solidGray--lajii";
export var roottype_solidOrange = "PlasmicLinkButton-module--roottype_solidOrange--Dt7Hp";
export var slotTargetText = "PlasmicLinkButton-module--slotTargetText--8fjQp";
export var slotTargetTexttype_blankGray = "PlasmicLinkButton-module--slotTargetTexttype_blankGray--ArAV1";
export var slotTargetTexttype_blankOrange = "PlasmicLinkButton-module--slotTargetTexttype_blankOrange--RMtMj";
export var slotTargetTexttype_outlineGray = "PlasmicLinkButton-module--slotTargetTexttype_outlineGray--xoIo6";
export var slotTargetTexttype_solidGray = "PlasmicLinkButton-module--slotTargetTexttype_solidGray--OeDg8";
export var slotTargetTexttype_solidOrange = "PlasmicLinkButton-module--slotTargetTexttype_solidOrange--7X2MZ";