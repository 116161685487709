// extracted by mini-css-extract-plugin
export var a = "plasmic_planty-module--a--ToQKd";
export var address = "plasmic_planty-module--address--VoW0e";
export var all = "plasmic_planty-module--all--bfZsY";
export var blockquote = "plasmic_planty-module--blockquote--57aax";
export var button = "plasmic_planty-module--button--UkMxD";
export var code = "plasmic_planty-module--code--ru+f2";
export var h1 = "plasmic_planty-module--h1--GatoZ";
export var h2 = "plasmic_planty-module--h2--GzRV4";
export var h3 = "plasmic_planty-module--h3--NCZb5";
export var h4 = "plasmic_planty-module--h4---DBZE";
export var h5 = "plasmic_planty-module--h5--DHTCo";
export var h6 = "plasmic_planty-module--h6--LeY1d";
export var img = "plasmic_planty-module--img--rJDt-";
export var input = "plasmic_planty-module--input--ACNRI";
export var li = "plasmic_planty-module--li--QgkrM";
export var ol = "plasmic_planty-module--ol--wxSXm";
export var p = "plasmic_planty-module--p--ZLtUU";
export var plasmic_default__a = "plasmic_planty-module--plasmic_default__a--rnK4T";
export var plasmic_default__blockquote = "plasmic_planty-module--plasmic_default__blockquote--9qNhd";
export var plasmic_default__code = "plasmic_planty-module--plasmic_default__code--HHRqb";
export var plasmic_default__component_wrapper = "plasmic_planty-module--plasmic_default__component_wrapper--v5ShF";
export var plasmic_default__h1 = "plasmic_planty-module--plasmic_default__h1--+xPN9";
export var plasmic_default__h2 = "plasmic_planty-module--plasmic_default__h2--TPaH5";
export var plasmic_default__h3 = "plasmic_planty-module--plasmic_default__h3--eqMkV";
export var plasmic_default__h4 = "plasmic_planty-module--plasmic_default__h4--7ucIF";
export var plasmic_default__h5 = "plasmic_planty-module--plasmic_default__h5--OPlcY";
export var plasmic_default__h6 = "plasmic_planty-module--plasmic_default__h6--GzCn1";
export var plasmic_default__inline = "plasmic_planty-module--plasmic_default__inline--uUizf";
export var plasmic_default__ol = "plasmic_planty-module--plasmic_default__ol---4bSZ";
export var plasmic_default__pre = "plasmic_planty-module--plasmic_default__pre--jzCTx";
export var plasmic_default__ul = "plasmic_planty-module--plasmic_default__ul--abk8K";
export var plasmic_default_styles = "plasmic_planty-module--plasmic_default_styles--TydQ1";
export var plasmic_page_wrapper = "plasmic_planty-module--plasmic_page_wrapper--GXbO9";
export var plasmic_tokens = "plasmic_planty-module--plasmic_tokens--MDOlP";
export var pre = "plasmic_planty-module--pre--utmuN";
export var root_reset = "plasmic_planty-module--root_reset--K3SrJ";
export var select = "plasmic_planty-module--select--jtzv3";
export var span = "plasmic_planty-module--span--X8PY2";
export var textarea = "plasmic_planty-module--textarea--0xv1i";
export var ul = "plasmic_planty-module--ul--n4ZK4";